import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";

import { getBankAccountsList } from "../../services/bankAccountDetails";

export default function BankAccountsList() {
  const { id } = useParams();
  const [bankAccountList, setBankAccountList] = useState(null);
  const [filters] = useState({
    iban: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getBankAccountsList()
      .then((data) => {
        const updatedData = data.map((item, index) => ({
          ...item,
          uniqueId: `${item.iban}-${index}`,
        }));
        setBankAccountList(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch bank accounts:", error);
        setLoading(false);
      });
  }, []);

  const actionBodyTemplate = () => {
    const handleClick = () => {
      navigate(`/update-bank-account-details/${id}`);
    };

    return (
      <button onClick={handleClick} className="p-link">
        Update
      </button>
    );
  };
  const handleCreateNewPayout = () => {
    navigate(`/add-bank-account-details/${id}`);
  };

  return (
    <div className="card">
      <div className="flex justify-content-end mb-3">
        <Button
          label="Create New Payout"
          icon="pi pi-plus"
          onClick={handleCreateNewPayout}
          className="p-button-success"
        />
      </div>
      <DataTable
        value={bankAccountList}
        paginator
        rows={10}
        dataKey="uniqueId"
        filters={filters}
        filterDisplay="row"
        loading={loading}
        emptyMessage="No customers found."
      >
        <Column
          field="iban"
          header="IBAN"
          filter
          filterPlaceholder="Search by IBAN"
          style={{ minWidth: "12rem" }}
          filterMatchMode="startsWith"
        />
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Search by Email"
          style={{ minWidth: "12rem" }}
          filterMatchMode="startsWith"
        />
        <Column
          header="Action"
          body={actionBodyTemplate}
          style={{ minWidth: "10rem" }}
        />
      </DataTable>
    </div>
  );
}
