import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

import "./ClearingDetails.css";
import { updateClearing } from "../../services/clearing";

const ClearingDetails = () => {
  const location = useLocation();
  const { details: initialDetails } = location.state || {};
  const [details, setDetails] = useState(initialDetails || {});

  useEffect(() => {
    console.log(details);
  }, [details]);

  const handleInputChange = (key, value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const handleUpdate = () => {
    console.log("Updated details:", details);
    updateClearing(details.mandantName, details).then((response) => {
      console.log("Update response:", response);
    });
  };

  return (
    <div className="clearing-details">
      <h1>Clearing Details</h1>
      {details && Object.keys(details).length > 0 ? (
        <Card className="details-card">
          <div className="details-grid">
            {Object.keys(details)
              .filter((key) => key.toLowerCase() !== "mandantname")
              .map((key) => (
                <div key={key} className="details-item">
                  <label htmlFor={key} className="details-label">
                    {key.replace(/([a-z])([A-Z])/g, "$1 $2")}:
                  </label>
                  <InputText
                    id={key}
                    value={details[key]}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                    className="details-input"
                    disabled={key.toLowerCase() === "email"}
                  />
                </div>
              ))}
          </div>
          <div className="update-button">
            <Button
              severity="success"
              label="Update"
              icon="pi pi-check"
              onClick={handleUpdate}
            />
          </div>
        </Card>
      ) : (
        <p>No details available.</p>
      )}
    </div>
  );
};

export default ClearingDetails;
