import React, { useState, useEffect, useRef } from "react";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

import "./ClearingList.css";
import {
  getAllBills,
  getAllNotes,
  getClearing,
  SyncCDR,
} from "../../services/clearing";

export default function ClearingList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const clearingData = await getClearing();
        if (!clearingData || clearingData.length === 0) {
          setData([]);
        } else {
          const transformedData = clearingData.map((item) => ({
            ...item,
            name: `${item.firstName} ${item.lastName}`,
            phone: item.phone,
            email: item.email.join(", "),
          }));
          setData(transformedData);
        }
      } catch (err) {
        setError("Failed to fetch clearing data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", { header: "Name" }),
    columnHelper.accessor("phone", { header: "Phone" }),
    columnHelper.accessor("email", { header: "Email" }),
    columnHelper.accessor("mandantName", { header: "Mandant Name" }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (row) => {
    navigate(`/clearing-details`, { state: { details: row.original } });
  };

  const handleGenerateBills = async () => {
    try {
      await getAllBills();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "All EMP bills generated successfully!",
        life: 3000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate EMP bills.",
        life: 3000,
      });
    }
  };

  const handleGenerateNotes = async () => {
    try {
      await getAllNotes();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "All credit notes generated successfully!",
        life: 3000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate credit notes.",
        life: 3000,
      });
    }
  };

  const handleSyncCDR = async () => {
    try {
      await SyncCDR();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "CDR synced successfully!",
        life: 3000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to sync CDR.",
        life: 3000,
      });
    }
  };

  return (
    <div className="clearing-list-container">
      <Toast ref={toast} />
      <div className="card flex justify-content-center">
        <Button
          label="Generate all EMP Bills"
          severity="success"
          onClick={handleGenerateBills}
        />
        <Button
          label="Generate all Credit Notes"
          severity="success"
          onClick={handleGenerateNotes}
        />
        <Button
          label="Sync CDRs from eRound"
          severity="success"
          onClick={handleSyncCDR}
        />
      </div>
      {loading && (
        <p className="loading-message">Loading data, please wait...</p>
      )}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && data.length === 0 && (
        <p className="no-data-message">No clearing data available.</p>
      )}
      {!loading && !error && data.length > 0 && (
        <div className="table-wrapper">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => handleRowClick(row)}
                  className="clickable-row"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
