import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

import { getStationDetails } from "../../../services/stations";
import EvseList from "./components/Evse/EvseList";
import OpeningHours from "./components/OpeningHours/OpeningHours";
import MapAndAddress from "./components/MapAndAddress/MapAndAddress";
import StickyActions from "./components/StickyActions/StickyActions";
import "./StationDetails.css";

export default function StationDetails() {
  const { id } = useParams();

  const [stationDetails, setStationDetails] = useState({}); 

  useEffect(() => {
    getStationDetails(id)
      .then((data) => {
        setStationDetails(data);
      })
      .catch((err) => {
        console.error("Error fetching station details:", err);
      });
  }, [id]);

  return (
    <div className="station-details-container">
      <Card
        className="wide-card"
        title={
          <h4 className="station-name">Station name: {stationDetails.name}</h4>
        }
      >
        <MapAndAddress
          coordinates={stationDetails.coordinates}
          address={stationDetails.address}
          city={stationDetails.city}
          state={stationDetails.state}
        />
        <Divider />
        <OpeningHours openingTimes={stationDetails.opening_times} />
        <EvseList evses={stationDetails.evses} />
        <StickyActions stationId={id} />
      </Card>
    </div>
  );
}
