import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  validateName,
  validateEmail,
  validateIban,
} from "../../utils/formValidations";
import {
  getBankAccountDetails,
  addBankAccountDetails,
} from "../../services/bankAccountDetails";
import InputField from "../components/InputField";

import "./UpdateBankAccountDetails.css";

export default function StationDetails() {
  const { id } = useParams();

  const [values, setValues] = useState({
    name: "",
    email: "",
    iban: "",
    bic: "",
  });

  const [errors, setErrors] = useState({
    nameError: null,
    emailError: null,
    ibanError: null,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBankAccountDetails = async () => {
      try {
        const bankAccountDetails = await getBankAccountDetails(id);
        console.log(bankAccountDetails);
        setValues({
          name: bankAccountDetails.account_holder_name,
          email: bankAccountDetails.email,
          iban: bankAccountDetails.iban,
          bic: bankAccountDetails.bic || "",
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBankAccountDetails();
  }, [id]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${id}Error`]: null,
    }));
  };

  const handleSave = () => {
    const nameValidation = validateName(values.name);
    const emailValidation = validateEmail(values.email);
    const ibanValidation = validateIban(values.iban);

    setErrors({
      nameError: nameValidation,
      emailError: emailValidation,
      ibanError: ibanValidation,
    });

    if (!nameValidation && !emailValidation && !ibanValidation) {
      addBankAccountDetails(
        id,
        values.name,
        values.iban,
        values.bic,
        values.email
      )
        .then(() => {
          alert("Bank account details added successfully");
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to update bank account details");
        });
    }
  };

  return (
    <div className="container">
      {loading ? (
        <div className="container">
          <ProgressSpinner />
          <div>Loading...</div>
        </div>
      ) : (
        <div className="custom-card-width">
          <div className="flex-between">
            <h4>Update Bank account information: </h4>
          </div>
          <div className="flex flex-column md:flex-row gap-3">
            <InputField
              id="name"
              label="Full Name*"
              value={values.name}
              onChange={handleChange}
              error={errors.nameError}
              icon="pi pi-user"
            />
            <InputField
              id="iban"
              label="IBAN*"
              value={values.iban}
              onChange={handleChange}
              error={errors.ibanError}
              icon="pi pi-building-columns"
            />
            <InputField
              id="bic"
              label="BIC"
              value={values.bic}
              onChange={handleChange}
              error={null}
              placeholder="(Optional)"
              icon="pi pi-building-columns"
            />
            <InputField
              id="email"
              label="Email*"
              value={values.email}
              onChange={handleChange}
              error={errors.emailError}
              icon="pi pi-at"
            />
          </div>
          <div className="button-container">
            <Button
              label="Save"
              className="p-button-raised save-button"
              onClick={handleSave}
            />
          </div>
        </div>
      )}
    </div>
  );
}
