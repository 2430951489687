import React, { useMemo } from "react";
import { Menubar } from "primereact/menubar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faSignOutAlt,
  faChargingStation,
  faShield,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import "./MenuBarNavigation.css";

export default function MenuBarNavigation() {
  const navigate = useNavigate();

  const items = useMemo(
    () => [
      {
        label: "Home",
        icon: () => (
          <FontAwesomeIcon
            style={{ color: "green" }}
            icon={faHome}
            size="2x"
            data-pr-tooltip="Home"
            className="menu-icon"
            id="menu-icon"
          />
        ),
        command: () => navigate("/"),
      },
      {
        label: "Station List",
        icon: () => (
          <FontAwesomeIcon
            style={{ color: "green" }}
            icon={faChargingStation}
            size="2x"
            data-pr-tooltip="Station List"
            className="menu-icon"
            id="station-list-icon"
          />
        ),
        command: () => navigate("/station-list"),
      },
      {
        label: "Clearing",
        icon: () => (
          <FontAwesomeIcon
            style={{ color: "green" }}
            icon={faShield}
            size="2x"
            data-pr-tooltip="Clearing"
            className="menu-icon"
            id="station-list-icon"
          />
        ),
        command: () => navigate("/clearing-list"),
      },
    ],
    [navigate]
  );
  const end = (
    <FontAwesomeIcon
      style={{ color: "green", cursor: "pointer" }}
      icon={faSignOutAlt}
      size="2x"
      data-pr-tooltip="Logout"
      className="menu-icon"
      id="logout-icon"
      onClick={() => navigate("/logout")}
    />
  );

  return (
    <div>
      <Menubar model={items} end={end} />
    </div>
  );
}
